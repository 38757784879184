import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { LoggedInUser } from '../models/LoggedInUser';
import { AdalService } from 'adal-angular4';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackBarComponent } from '../components/custom-snack-bar/custom-snack-bar.component';
import { stringify } from 'querystring';
import { NavbarService } from './navbar.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl: string;
  private confirmEmailUrl = 'reg/v1/confirm';
  private loginWithAdalUrl = 'auth/v1/gettoken';
  private logOutFromBE = 'auth/v1/logout';
  public userLoginEvent = new EventEmitter<LoggedInUser>();

  currentUserValue: LoggedInUser;

  constructor(private http: HttpClient,
              private adalService: AdalService,
              private snackBar: MatSnackBar,
              private navbarService: NavbarService,
              private router: Router) {
    this.apiUrl = environment.apiUrl;
    // adal
    this.adalService.init(environment.adalConfig);
    this.adalService.handleWindowCallback();

    this.currentUserValue = JSON.parse(localStorage.getItem('currentUser'));
    // if (this.currentUserValue) {
    //   this.userLoginEvent.emit(this.currentUserValue);
    // }
  }

  get currUser(): LoggedInUser {
    return this.currentUserValue;
  }

  emailConfirmation(token: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': 'my-auth-token'
        'Access-Control-Allow-Origin': '*'
      })
    };

    return this.http.post(this.apiUrl + this.confirmEmailUrl + '/' + token, httpOptions);
  }

  get isAdalLoginActive(): boolean {
    return this.adalService.userInfo.authenticated;
  }

  async login() {
    if (this.adalService.userInfo.authenticated) {
      if (!this.currentUserValue) {

        this.loginWithAdalToken(this.adalService.userInfo.token).subscribe(
          response => {
            this.currentUserValue = new LoggedInUser();

            this.currentUserValue.JWTToken = response.token;
            this.currentUserValue.firstName = this.adalService.userInfo.profile.given_name;
            this.currentUserValue.lastName = this.adalService.userInfo.profile.family_name;
            this.currentUserValue.email = this.adalService.userInfo.profile.email;

            localStorage.setItem('currentUser', JSON.stringify(this.currentUserValue));

            this.snackBar.openFromComponent(CustomSnackBarComponent, {
              panelClass: 'snack-bar-container-succesfully',
              data: {
                message: 'Logged in as '.concat(this.currentUserValue.firstName, ' ', this.currentUserValue.lastName)
              }
            });
            this.navbarService.show();
            // console.log(this.currentUserValue);
            // this.userLoginEvent.emit(this.currentUserValue);
            this.router.navigate(['/stations']);
          },
          _ => {
            this.snackBar.openFromComponent(CustomSnackBarComponent, {
              panelClass: 'snack-bar-container-warning',
              data: {
                message: 'Something went wrong with the login request'
              }
            });
          });
      } else {
        this.navbarService.show();
        // console.log(this.currentUserValue);
        // this.userLoginEvent.emit(this.currentUserValue);
        this.router.navigate(['/stations']);
      }
    } else {
      this.adalService.login();
    }
  }

  loginWithAdalToken(adalToken: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.adalService.userInfo.token,
        'Access-Control-Allow-Origin': '*'
      })
    };

    return this.http.post<{token: string}>(this.apiUrl + this.loginWithAdalUrl + '/', {token: adalToken}, httpOptions);
  }

  async logout() {
    localStorage.removeItem('currentUser');
    await this.logoutFromBackEnd();
    this.adalService.logOut();
  }

  logoutFromBackEnd(): Promise<any> {
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     'Access-Control-Allow-Origin': '*'
    //   })
    // };

    return this.http.post(this.apiUrl + this.logOutFromBE, {}).toPromise();
  }

  validateJWTToken(jwtToken: string): Promise<boolean> {
    console.log('TODO: ', jwtToken);
    return of(true).toPromise();
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: snack-bar

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
