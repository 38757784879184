export const environment = {
  production: true,
  debug: false,
  apiUrl: 'https://axegazwebapi.azurewebsites.net/',
  adalConfig: {
    tenant: '8fabe025-b1db-4f0c-82ce-2f4a3ce2b985',
    clientId: '58d7059a-2649-4b21-9cb8-39fbec5a5924',
    cacheLocation: 'localStorage',
    redirectUri: 'https://axecardwebapp.z6.web.core.windows.net'
    // endpoints: {
    //   'https://axegazwebapi.azurewebsites.net/': '58d7059a-2649-4b21-9cb8-39fbec5a5924'
    // }
  }
};
