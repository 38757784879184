import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { of, Observable } from 'rxjs';

import { Role } from '../models/role';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private apiUrl: string;
  private getUserRoles = 'level/v1/get';
  private getColumnsForUserRole = 'level/v1/getfields';
  private updateRoleForUser = 'level/v1/get';
  private updateRolesForUser = 'level/v1/addorupdatelist';

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

  getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(this.apiUrl + this.getUserRoles);
  }

  getColumnsForGrid(gridName: string): Observable<string[]> {
    return this.http.get<string[]>(this.apiUrl + this.getColumnsForUserRole + '/' + gridName);
  }

  updateRole(role: Role) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        // 'Authorization': 'my-auth-token'
        'Access-Control-Allow-Origin': '*'
      })
    };

    return this.http.put(this.apiUrl + this.updateRoleForUser, JSON.stringify(role), httpOptions);
  }

  pushRoles(roles: Role[]) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        // 'Authorization': 'my-auth-token'
        'Access-Control-Allow-Origin': '*'
      })
    };

    return this.http.post(this.apiUrl + this.updateRolesForUser, JSON.stringify(roles), httpOptions);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: snack-bar

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
