import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TrainingGuideService } from 'src/app/services/training-guide.service';
import { ColumnModel } from 'src/app/models/column-model';
import { TrainingGuideModel } from 'src/app/models/training-guide-model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LanguageModel } from 'src/app/models/language-model';
import { MatSnackBar } from '@angular/material';
import { ConfirmDialogComponent } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { CustomSnackBarComponent } from '../custom-snack-bar/custom-snack-bar.component';
import { TrainingGuideDto } from 'src/app/models/training-guide-dto';
import { RefreshGuideTrainingDto } from 'src/app/models/refresh-guide-training-dto';
import { UpdateTrainingGuideDialogComponent } from 'src/app/dialogs/update-training-guide-dialog/update-training-guide-dialog.component';

@Component({
  selector: 'app-guides',
  templateUrl: './guides.component.html',
  styleUrls: ['./guides.component.scss']
})
export class GuidesComponent implements OnInit {
  _PAGINATOR_DEFAULT_PAGE_SIZE = 15;

  documents: TrainingGuideModel[];
  cols: ColumnModel[];
  stateValues: string[];
  displayedColumns: string[];
  dataSourceDocuments: MatTableDataSource<TrainingGuideModel>;

  uploadDocumentForm: FormGroup;
  chosenTrainingFile: File;
  chosenGuideFile: File;

  documentLanguages: LanguageModel[];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private documentsService: TrainingGuideService,
              public dialog: MatDialog,
              private snackBar: MatSnackBar,
              private changeDetectorRefs: ChangeDetectorRef) {}

  ngOnInit() {
    this.uploadDocumentForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      language: new FormControl('', [Validators.required]),
      trainingName: new FormControl('', [Validators.required]),
      guideName: new FormControl('', [Validators.required])
    });

    this.refreshMiscData();
    this.refreshTableData();

    this.cols = [
      {field: 'name', header: 'Name', show: true, type: 'string', required: true},
      {field: 'trainingFileName', header: 'Training', show: true, type: 'download-link-training', required: true},
      {field: 'guideFileName', header: 'Guide', show: true, type: 'download-link-guide', required: true},
      {field: 'languageX', header: 'Language', show: true, type: 'lang', required: true}
    ];

    // pipe a cols-ra
    this.displayedColumns = ['name', 'trainingFileName', 'guideFileName', 'languageX', 'delete', 'update'];
  }

  private refreshMiscData() {
    this.documentsService.getDocLanguages()
        .subscribe(data => {
          this.documentLanguages = data;
        });
  }

  private refreshTableData() {
    this.documentsService.getDocuments()
        .subscribe(data => {
          this.documents = data;
          this.dataSourceDocuments = new MatTableDataSource(data);
          this.dataSourceDocuments.paginator = this.paginator;
          this.dataSourceDocuments.paginator.pageSize = this._PAGINATOR_DEFAULT_PAGE_SIZE;
          this.dataSourceDocuments.sort = this.sort;
        });
  }

  applyTrainingFilter(filterValue: string) {
    this.dataSourceDocuments.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceDocuments.paginator) {
      this.dataSourceDocuments.paginator.firstPage();
    }
  }

  /**
   * Get the chosen file from the specific input element.
   * @param event object of binded change event
   * @param type 0 -> training, 1 -> guide, 2 -> refresh
   */
  onFileSelected(event: any, type: number) {
    let inputNode: any;
    if (type === 2) {
      inputNode = document.querySelector('#fileRefresh');
      this.refreshDocument(inputNode.files[0]);
    } else if (type === 0) {
      inputNode = document.querySelector('#fileTraining');
      this.chosenTrainingFile = inputNode.files[0];
      this.uploadDocumentForm.get('trainingName').setValue(this.chosenTrainingFile.name);
    } else {
      inputNode = document.querySelector('#fileGuide');
      this.chosenGuideFile = inputNode.files[0];
      this.uploadDocumentForm.get('guideName').setValue(this.chosenGuideFile.name);
    }
  }

  uploadDocument(): void {
    const model = {} as TrainingGuideDto;

    model.Name = this.uploadDocumentForm.get('name').value;
    model.Language = this.uploadDocumentForm.get('language').value;
    model.TrainingFileToUpload = this.chosenTrainingFile;
    model.GuideFileToUpload = this.chosenGuideFile;

    this.documentsService.addDocument(model)
      .subscribe(res => {
        this.documents = this.dataSourceDocuments.data;
        this.documents.push(res.resDoc);
        this.dataSourceDocuments.data = this.documents;

        this.snackBar.openFromComponent(CustomSnackBarComponent, {
          panelClass: 'snack-bar-container-succesfully',
          data: {
            message: 'Document has been uploaded!'
          }
        });
      }, err => {
        this.snackBar.openFromComponent(CustomSnackBarComponent, {
          panelClass: 'snack-bar-container-warning',
          data: {
            message: 'Error: ' + err
          }
        });
      });
  }

  deleteDocument(doc: TrainingGuideModel): void {
    const index = this.dataSourceDocuments.data.indexOf(doc);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '700px',
      data: {
        title: 'Delete document!',
        message: 'Do you want to delete "'.concat(doc.name, '" document?'),
        action: 'Delete',
        actionButtonType: 'warn'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.documentsService.deleteDocument(doc.id)
          .subscribe(() => {
            this.documents = this.dataSourceDocuments.data;
            this.documents.splice(index, 1);
            this.dataSourceDocuments.data = this.documents;

            this.snackBar.openFromComponent(CustomSnackBarComponent, {
              panelClass: 'snack-bar-container-succesfully',
              data: {
                message: 'Document has been deleted!'
              }
            });
          }, err => {
            this.snackBar.openFromComponent(CustomSnackBarComponent, {
              panelClass: 'snack-bar-container-warning',
              data: {
                message: 'Error: ' + err
              }
            });
          });
      }
    });
  }

  refreshDocument(dto: TrainingGuideDto) {
    if (dto) {
      this.documentsService.refreshFile(dto)
        .subscribe(() => {
          this.refreshTableData();
          this.snackBar.openFromComponent(CustomSnackBarComponent, {
            panelClass: 'snack-bar-container-succesfully',
            data: {
              message: 'Document has been refreshed!'
            }
          });
        }, err => {
          this.snackBar.openFromComponent(CustomSnackBarComponent, {
            panelClass: 'snack-bar-container-warning',
            data: {
              message: 'Error: ' + err
            }
          });
      });
    }
  }

  downloadDocument(id: string) {
    this.documentsService.downloadTrainingGuidePair(id)
      .subscribe(res => {
        this.refreshTableData();

        // Download pop-up for file
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob([res], {type: res.type}));
        const filename = res.name;
        if (filename) {
          downloadLink.setAttribute('download', filename);
        }
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        this.snackBar.openFromComponent(CustomSnackBarComponent, {
          panelClass: 'snack-bar-container-succesfully',
          data: {
            message: 'Document has been downloaded!'
          }
        });
      }, err => {
        this.snackBar.openFromComponent(CustomSnackBarComponent, {
          panelClass: 'snack-bar-container-warning',
          data: {
            message: 'Error: ' + err
          }
        });
    });
  }

  openUpdateTrainingGuideModal(row: TrainingGuideModel): void {
    const dialogRef = this.dialog.open(UpdateTrainingGuideDialogComponent, {
      width: '700px',
      data: {
        doc: row
      }
    });

    dialogRef.afterClosed().subscribe((result: TrainingGuideDto) => {
      if (result) {
        this.documentsService.refreshFile(result)
          .subscribe(res => {
            const index = this.documents.findIndex(x => x.id === res.resDoc.id);

            this.documents = this.dataSourceDocuments.data;
            this.documents[index] = res.resDoc as TrainingGuideModel;

            this.dataSourceDocuments.data = this.documents;
            this.changeDetectorRefs.detectChanges();

            this.snackBar.openFromComponent(CustomSnackBarComponent, {
              panelClass: 'snack-bar-container-succesfully',
              data: {
                message: 'Training-Guide has been updated!'
              }
            });
          }, err => {
            this.snackBar.openFromComponent(CustomSnackBarComponent, {
              panelClass: 'snack-bar-container-warning',
              data: {
                message: 'Error: ' + err
              }
            });
          });
      }
    });
  }

  downloadTgFile(fileName: string): any {
      this.documentsService.downloadTrainingOrGuideFile(fileName)
      .subscribe(res => {
        this.snackBar.openFromComponent(CustomSnackBarComponent, {
          panelClass: 'snack-bar-container-succesfully',
          data: {
            message: 'Document has been downloaded! Opening in new tab...'
          }
        });

        // Open PDF in new tab
        window.open(window.URL.createObjectURL(new Blob([res], {type: res.type})));
      }, err => {
        this.snackBar.openFromComponent(CustomSnackBarComponent, {
          panelClass: 'snack-bar-container-warning',
          data: {
            message: 'Error: ' + err
          }
        });
      });
    }
}
