export class Help {
    title: string;
    content: string;
    imageSrc: string;
    manual: {
      action: string,
      description: {
        text: string,
        imageSrc: string,
        picSize: string
      }[]
    }[];
}

export const HELP_DATA = [
    {
      title: 'Stations page manual',
      content: 'Edit stations',
      imageSrc: 'assets/stations/help_page.PNG',
      manual: [
        {
          action: 'Edit',
          description: [
            {
              text: 'Show a modal where you can edit station (This is just a local change, not permanent!)',
              imageSrc: 'assets/stations/edit_button.PNG',
              picSize: 'small'
            }
          ]
        },
        {
          action: 'Discard All Changes',
          description: [
            {
              text: 'Download the latest data from server (This will be discard all of your local changes!)',
              imageSrc: 'assets/stations/discard_all_changes_button.PNG',
              picSize: 'small'
            }
          ]
        },
        {
          action: 'Commit All Changes',
          description: [
            {
              text: 'Upload the current version form the WebUI to server and delegate to' +
                'the Mobile App (This will be override the server version!)',
              imageSrc: 'assets/stations/commit_all_changes_button.PNG',
              picSize: 'small'
            }
          ]
        },
        {
          action: 'Show station on map',
          description: [
            {
              text: 'The station name is clickable',
              imageSrc: 'assets/stations/name_text.PNG',
              picSize: 'small'
            },
            {
              text: 'If you click on the station name a dialog will appear with google map on it.',
              imageSrc: 'assets/stations/google_map_dialog.PNG',
              picSize: 'full'
            }
          ]
        },
        {
          action: 'Warning icon next to station name / Red background on row',
          description: [
            {
              text: `'Missing field(s)! The station will not shown on mobile app!'`,
              imageSrc: 'assets/stations/warning_name_text.PNG',
              picSize: 'small'
            },
            {
              text: '<b>Latitude, Longitude, Name is required for mobile app!</b>',
              imageSrc: undefined,
              picSize: 'small'
            }
          ]
        },
        {
          action: 'Fuel status in grid',
          description: [
            {
              text: `There is 3 statuses for fuels: 'open' with yeallow, 'temporarily closed' with yellow,' +
                'unavailable' with red<br>Example:`,
              imageSrc: 'assets/stations/fuel_status.PNG',
              picSize: 'medium'
            },
            {
              text: '1. CNG: open<br>2. LNG: temporarily closed<br>3. CNG: unavailable',
              imageSrc: undefined,
              picSize: 'medium'
            }
          ]
        },
        {
          action: 'Connect training-guide pair',
          description: [
            {
              text:
                `To connect a training-guide pair to a station you have to select the training-guide you want in the edit dialog. ' +
                'You can set the station to not have any training-guide linked by selecting the empty select option.`,
              imageSrc: 'assets/stations/training_guide_select.PNG',
              picSize: 'full'
            }
          ]
        }
      ]
    },
    {
      title: 'Guides and Trainings manual',
      content: 'Overseeing Users And Corresponding Guides',
      imageSrc: 'assets/guides_trainings/help_page.PNG',
      manual: [
        {
          action: 'Upload',
          description: [
            {
              text: `All Training-Guide pairs needs a unique name, a language `
              + `and a training and guide file.`,
              imageSrc: 'assets/guides_trainings/upload_form.PNG',
              picSize: 'full'
            }
          ]
        },
        {
          action: 'Update Training of Guide File',
          description: [
            {
              text: 'To update a training-guide pair, you have to click the update button on the row you want to update.',
              imageSrc: 'assets/guides_trainings/update_button.PNG',
              picSize: 'small'
            },
            {
              text: 'You can update eather one of the files or both of them. You have to select a file for training or guide or for both.'
              + ' The name of the training-guide pair or the language cannot be updated!',
              imageSrc: 'assets/guides_trainings/update_modal.PNG',
              picSize: 'full'
            }
          ]
        },
        {
          action: 'Download',
          description: [
            {
              text: 'To download a training-guide pair (in a zip package), click the download button on the training-guide row '
                        + 'you want to download the files for.',
              imageSrc: 'assets/guides_trainings/download_button.PNG',
              picSize: 'small'
            }
          ]
        },
        {
          action: 'Delete',
          description: [
            {
              text: 'To delete a training-guide pair, click the download button on the training-guide row '
                        + 'you want to delete.',
              imageSrc: 'assets/guides_trainings/delete_button.PNG',
              picSize: 'small'
            }
          ]
        }
      ]
    },
    {
      title: 'Users page manual',
      content: 'Overseeing Users And Managing Their Levels, Customers...',
      imageSrc: 'assets/users/users_page.PNG',
      manual: [
        {
          action: 'Set customer or level for user',
          description: [
            {
              text: `To associate a user with a corresponding customer, use the combobox. To associate it with none of the customers, use th 'None' option.`,
              imageSrc: 'assets/users/combo_customer.PNG',
              picSize: 'medium'
            },
            {
              text: 'To set a specific level to a customer, use the level combobox. All users can be promoted or demoted to any level.',
              imageSrc: 'assets/users/combo_level.PNG',
              picSize: 'medium'
            }
          ]
        },
        {
          action: 'Discard All Changes',
          description: [
            {
              text: 'Discard local changes related to users.',
              imageSrc: 'assets/users/discard_all_changes_button.PNG',
              picSize: 'small'
            }
          ]
        },
        {
          action: 'Commit All Changes',
          description: [
            {
              text: 'Update users on server (This will override the server version!)',
              imageSrc: 'assets/users/commit_all_changes_button.PNG',
              picSize: 'small'
            }
          ]
        }
      ]
    },
    {
      title: 'Settings page manual',
      content: 'Manage user level/role permissions',
      imageSrc: 'assets/settings/help_page.PNG',
      manual: [
        {
          action: 'Default Levels',
          description: [
            {
              text: `Unregistered users are automatically assigned to Level 1. Upon registration a user is assigned to Level 2 automatically but later can be assigned to any level including Level 1.`,
              imageSrc: 'assets/users/level1_level2.png',
              picSize: 'big'
            }
          ]
        },
        {
          action: 'Level/Role Settings',
          description: [
            {
              text: 'You can open the permission settings window for the users with the Change Permission button.',
              imageSrc: 'assets/settings/change_permission_button.png',
              picSize: 'small'
            },
            {
              text: '',
              imageSrc: 'assets/settings/change_permission_dialog_full.PNG',
              picSize: 'big'
            }
          ]
        },
        {
          action: 'Change data access permissions',
          description: [
            {
              text: 
                `<p><em>ValidStationMarker</em> columns represents the data the user can see in the Axecard mobile application (data for stationmarkers) while<br>` +
                `<em>TransactionExt</em> columns are for the data shown in the Transaction Web application.</p>` +
                `<p>Example:<br><p>` +
                `<ul>` +
                `<li>'"Level2" has access to...' columns: Columns what the user will see with this permission ('Level2' in the example).</li>` +
                `<li>'Grid column list' columns: Other available columns what the user will not see with this permission.</li>` +
                `</ul>`,
              imageSrc: 'assets/settings/change_permission_dialog.PNG',
              picSize: 'big'
            },
            {
              text:
                `<p><u>About tgFileName, trainingGuideID, certificated fields</u>, these control the training and guide flow, so it is a good practice to select/deselect these fields altogether.</p>`,
              imageSrc: 'assets/settings/change_permission_dialog_tg.PNG',
              picSize: 'big'
            }
          ]
        },
        {
          action: 'Rename Level',
          description: [
            {
              text: 'Change the name of a level',
              imageSrc: 'assets/settings/change_levelname.png',
              picSize: 'medium'
            }
          ]
        },
        {
          action: 'Toggle Transactions',
          description: [
            {
              text: 'Enable or disable transaction acces for the users with this level',
              imageSrc: 'assets/settings/toggle_transactions.png',
              picSize: 'medium'
            }
          ]
        },
        {
          action: 'Discard All Changes',
          description: [
            {
              text: 'Download the latest data from server (This will be discard all of your local changes!)',
              imageSrc: 'assets/settings/discard_all_changes_button.PNG',
              picSize: 'small'
            }
          ]
        },
        {
          action: 'Commit All Changes',
          description: [
            {
              text: 'Update Roles/Levels permissions on server (This will be override the server version!)',
              imageSrc: 'assets/settings/commit_all_changes_button.PNG',
              picSize: 'small'
            }
          ]
        }
      ]
    }
  ];
