import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Help } from 'src/app/models/help';

@Component({
  selector: 'app-help-dialog',
  templateUrl: './help-dialog.component.html',
  styleUrls: ['./help-dialog.component.scss']
})
export class HelpDialogComponent {

  helpDescriptor: Help;

  constructor(public dialogRef: MatDialogRef<HelpDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
                this.helpDescriptor = data.helpDescriptor;
              }

}
