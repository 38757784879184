import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from 'src/app/models/user-models';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UsersService } from 'src/app/services/users.service';
import { MatDialog } from '@angular/material/dialog';
import { ColumnModel } from 'src/app/models/column-model';
import { SelectionModel } from '@angular/cdk/collections';
import { TrainingGuideModel } from 'src/app/models/training-guide-model';
import { TrainingGuideService } from 'src/app/services/training-guide.service';
import { ConfirmDialogComponent } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { CustomSnackBarComponent } from '../custom-snack-bar/custom-snack-bar.component';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  _PAGINATOR_DEFAULT_PAGE_SIZE = 15;

  userCols: ColumnModel[];
  certificatedTrainingGuidesCols: ColumnModel[];

  users: User[];
  certificatedTrainingGuides: TrainingGuideModel[];

  stateValues: string[];

  displayedUserColumns: string[];
  displayedCertificatedTrainingGuidesColumns: string[];

  dataSourceUsers: MatTableDataSource<User>;
  dataSourceCertificatedTrainingGuides: MatTableDataSource<TrainingGuideModel>;

  userSelection: SelectionModel<User>;

  trainingGuides: TrainingGuideModel[];

  customersComboOptions: [string, string][];
  levelComboOptionsDictionary: { [name: string]: string };

  editedRowId: Set<string> = new Set<string>();

  @ViewChild('masterPaginator', {static: true}) masterPaginator: MatPaginator;
  @ViewChild('detailsPaginator', {static: true}) detailsPaginator: MatPaginator;
  @ViewChild('usersTable', {static: true}) userTableSort: MatSort;
  @ViewChild('achievementsTable', {static: true}) achievementTableSort: MatSort;

  constructor(private snackBar: MatSnackBar,
              private usersService: UsersService,
              private tgService: TrainingGuideService,
              public dialog: MatDialog) {
    this.customersComboOptions = [];
    this.levelComboOptionsDictionary = {};
  }

  ngOnInit() {
    this.userCols = [
      {field: 'id', header: 'Id', show: false, type: 'string', required: true, disabled: false},
      {field: 'firstName', header: 'First Name', show: true, type: 'string', required: true, disabled: false},
      {field: 'lastName', header: 'Last Name', show: true, type: 'string', required: true, disabled: false},
      {field: 'company', header: 'Company', show: true, type: 'string', required: true, disabled: false},
      {field: 'email', header: 'Email', show: true, type: 'string', required: true, disabled: false },
      {field: 'email_enabled', header: 'Email Notification', show: true, type: 'checkbox', required: true, disabled: false,
        checkEnableCondition: (row) => {
          const fieldValue = row['email'];
          return fieldValue !== undefined && fieldValue !== null && (fieldValue + '').length > 0 
        }},
      {field: 'phone', header: 'Phone', show: true, type: 'string', required: true, disabled: false },
      {field: 'sms_enabled', header: 'SMS Notification', show: true, type: 'checkbox', required: true, disabled: false,
        checkEnableCondition: (row) => {
          const fieldValue = row['phone'];
          return fieldValue !== undefined && fieldValue !== null && (fieldValue + '').length > 0 
        }},
      {field: 'customer', header: 'Customer', show: true, type: 'customer_combo', required: true, disabled: false},
      {field: 'level', header: 'Level', show: true, type: 'level_combo', required: true, disabled: false}
    ];

    this.certificatedTrainingGuidesCols = [
      {field: 'name', header: 'Name', show: true, type: 'string', required: true},
      {field: 'trainingFileName', header: 'Training', show: true, type: 'string', required: true},
      {field: 'guideFileName', header: 'Guide', show: true, type: 'string', required: true},
      {field: 'languageX', header: 'Language', show: true, type: 'lang', required: true},
      {field: 'completed', header: 'Completed', show: true, type: 'completed', required: true},
      {field: 'date_of_completition', header: 'Completion Date', show: true, type: 'date_of_completition', required: true},
    ];

    this.displayedUserColumns = [
      'firstName', 'lastName', 'company', 'email', 'email_enabled', 'phone', 'sms_enabled', 'customer', 'level'
    ];
    this.displayedCertificatedTrainingGuidesColumns = [
      'name', 'trainingFileName', 'guideFileName', 'languageX', 'completed', 'date_of_completition'
    ];

    this.userSelection = new SelectionModel<User>(false, []);

    this.refreshTrainingGuideList();
    this.setupUsersGrid();
  }

  private refreshTrainingGuideList() {
    this.tgService.getDocuments()
      .subscribe(tgs => {
        this.trainingGuides = tgs;
      });
  }

  private setupUsersGrid() {
    this.usersService.getUsers()
        .subscribe(users => {
          this.users = users;
          this.dataSourceUsers = new MatTableDataSource(this.users);
          this.dataSourceUsers.paginator = this.masterPaginator;
          this.dataSourceUsers.paginator.pageSize = this._PAGINATOR_DEFAULT_PAGE_SIZE;
          this.dataSourceUsers.sort = this.userTableSort;
          this.setupAchievementGrid();
        });
    this.usersService.getCustomerComboOptions()
      .subscribe(options => {
        this.customersComboOptions = options;
      });
    this.usersService.getLevelComboOptions()
      .subscribe(levels => {
        this.levelComboOptionsDictionary = levels;
      });
  }

  private setupAchievementGrid() {
    if (this.users && this.users.length > 0 && this.userSelection && this.userSelection.selected && this.userSelection.selected[0]) {
      const selected = this.userSelection.selected[0];
      this.certificatedTrainingGuides = selected.trainingGuides;
    } else {
      this.certificatedTrainingGuides = [];
    }
    this.dataSourceCertificatedTrainingGuides = new MatTableDataSource(this.certificatedTrainingGuides);
    this.dataSourceCertificatedTrainingGuides.paginator = this.detailsPaginator;
    this.dataSourceCertificatedTrainingGuides.paginator.pageSize = this._PAGINATOR_DEFAULT_PAGE_SIZE;
    this.dataSourceCertificatedTrainingGuides.sort = this.achievementTableSort;
  }

  private getTrainingGuidesFilteredByCertificated(user: User): TrainingGuideModel[] {
    const tmp = [];
    user.certificatedTrainings.forEach(x => {
      this.trainingGuides.forEach(y => {
        if (x.trainingGuideID === y.id) {
          tmp.push(y);
        }
      });
    });
    return tmp;
  }

  private refreshCertificatedTrainingGuidesGrid() {
    if (this.users && this.userSelection && this.userSelection.selected && this.userSelection.selected[0]) {
      this.certificatedTrainingGuides = this.getTrainingGuidesFilteredByCertificated(this.userSelection.selected[0]);
    } else {
      this.certificatedTrainingGuides = [];
    }
    this.dataSourceCertificatedTrainingGuides.data = this.certificatedTrainingGuides;
  }

  applyUsersFilter(filterValue: string) {
    this.dataSourceUsers.filter = filterValue.trim().toLowerCase();
    if (this.dataSourceUsers.paginator) {
      this.dataSourceUsers.paginator.firstPage();
    }
  }

  applyAchievementsFilter(filterValue: string) {
    this.dataSourceUsers.filter = filterValue.trim().toLowerCase();
    if (this.dataSourceUsers.paginator) {
      this.dataSourceUsers.paginator.firstPage();
    }
  }

  selectUserRow(row: User): void {
    if (this.userSelection.isSelected(row)) {
      this.userSelection.deselect(row);
    } else {
      this.userSelection.select(row);
    }
    this.refreshCertificatedTrainingGuidesGrid();
  }

  isSelected(row: User): boolean {
    return this.userSelection.isSelected(row);
  }

  isThereSelectedUser(): boolean {
    return this.userSelection && this.userSelection.selected && this.userSelection.selected.length > 0;
  }

  getCompletitionDateOfTraining(row: TrainingGuideModel) {
    if (this.userSelection.selected[0].certificatedTrainings) {
      return this.userSelection.selected[0].certificatedTrainings.find(x => x.trainingGuideID === row.id).timestamp;
    } else {
      return '';
    }
  }

  updateUserCheckbox(event: any, user: User, field_name: string) {
    user[field_name] = event.checked;
    this.editedRowId.add(user.id);
  }

  updateUserCustomer(event: any, user: User) {
    if (event.value == 'NONE') {
      user.customerId = null;
      user.customerName = null;
    } else {
      user.customerId = event.value;
      user.customerName = this.customersComboOptions.find(x => x[0] == event.value)[1];
    }
    this.editedRowId.add(user.id);
  }

  updateUserLevel(event: any, user: User) {
    user.level = event.value;

    this.editedRowId.add(user.id);
  }

  refreshGrid() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '700px',
      data: {
        title: 'Refresh users grid!',
        message: 'This action will be discard all of your local changes!',
        action: 'Refresh'
      }
    });

    const filterValue = !!this.dataSourceUsers ? this.dataSourceUsers.filter : '';

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.usersService.getUsers()
          .subscribe(users => {
            this.editedRowId.clear();

            this.users = users;
            this.dataSourceUsers = new MatTableDataSource(this.users);
            this.dataSourceUsers.paginator = this.masterPaginator;
            this.dataSourceUsers.paginator.pageSize = this._PAGINATOR_DEFAULT_PAGE_SIZE;
            this.dataSourceUsers.sort = this.userTableSort;
            this.setupAchievementGrid();

            this.applyUsersFilter(filterValue);

            this.snackBar.openFromComponent(CustomSnackBarComponent, {
              panelClass: 'snack-bar-container-succesfully',
              data: {
                message: 'Station grid has been updated!'
              }
            });
          });
      }
    });
  }

  save() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '700px',
      data: {
        title: 'Upload users!',
        message: 'This action will be upload all of your local changes to the server!',
        action: 'Upload'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        var success = true;
        this.editedRowId.forEach(async id => {
          await this.usersService.updateUser(this.users.find(x => x.id == id)).toPromise().then(_ => {
            this.editedRowId.delete(id);
          }, err => {
            success = false;
            this.snackBar.openFromComponent(CustomSnackBarComponent, {
              panelClass: 'snack-bar-container-warning',
              data: {
                message: 'Error: ' + JSON.stringify(err)
              }
            });
          });
        });

        if(success){
          this.snackBar.openFromComponent(CustomSnackBarComponent, {
            panelClass: 'snack-bar-container-succesfully',
            data: {
              message: 'The list has been updated succesfully!'
            }
          });
        } else {
          this.snackBar.openFromComponent(CustomSnackBarComponent, {
            panelClass: 'snack-bar-container-warning',
            data: {
              message: 'Not all elemnents could have been saved.'
            }
          });
        }
      }
    });
  }

}
