import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { NavbarService } from 'src/app/services/navbar.service';

@Component({
  selector: 'app-email-confirm',
  templateUrl: './email-confirm.component.html',
  styleUrls: ['./email-confirm.component.scss']
})
export class EmailConfirmComponent implements OnInit {
  text = 'Registration confirmation in progress!';
  subtext: string;
  queryInProgress = true;
  token: string;
  mailTo: string;

  constructor(private route: ActivatedRoute,
              private authService: AuthService,
              private nav: NavbarService) {
                this.nav.hide();
              }

  ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token');
    if (!this.token) {
      this.text = 'Token is invalid or not provided!';
      this.queryInProgress = false;
    } else {
      this.authService.emailConfirmation(this.token).subscribe(
      (result) => {
        this.text = 'Registration is completed!';
        this.subtext = 'Now you can login and use AxeCard app!';
        this.queryInProgress = false;
      },
      (error) => {
        this.text = 'Registration confirmation is failed!';
        this.subtext = 'For more information: ',
        this.mailTo = 'support@axegaz.com',
        this.queryInProgress = false;
      });
    }
  }

}
