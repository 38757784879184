import { Component, Output, EventEmitter, Input } from '@angular/core';
import { version } from '../../../../package.json';
import { NavbarService } from 'src/app/services/navbar.service.js';
import { LoggedInUser } from 'src/app/models/LoggedInUser.js';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Output() public sidenavToggle = new EventEmitter();
  title = 'AxeCard WebApp' + (environment.debug ? ' TEST' : '');
  version = version;
  @Input() user: LoggedInUser;

  constructor(private nav: NavbarService) { }

  onToggleSidenav() {
    this.sidenavToggle.emit();
  }
}
