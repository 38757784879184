import { TrainingGuideModel } from './training-guide-model';

export class Station {
    ID: string;
    latitude: number;
    longitude: number;
    name: string;
    country: string;
    city: string;
    cp: string;
    address: string;
    isOperational: boolean;
    fuels: { cng: string; lng: string };
    details: string;
    notices: string;
    isValid: boolean;
    trainingGuide?: TrainingGuideModel | any;
    trainingGuides?: TrainingGuideModel[];
}
