import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';

import { Station } from 'src/app/models/station';

import { StationsService } from 'src/app/services/stations.service';
import { StationComponent } from '../../dialogs/station-dialog/station.component';
import { StationDescriptor } from '../../models/station-descriptor';
import { MapDialogComponent } from '../../dialogs/map-dialog/map-dialog.component';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackBarComponent } from '../custom-snack-bar/custom-snack-bar.component';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-stations',
  templateUrl: './stations.component.html',
  styleUrls: ['./stations.component.scss']
  /*encapsulation : ViewEncapsulation.None*/
})
export class StationsComponent implements OnInit {
  _PAGINATOR_DEFAULT_PAGE_SIZE = 15;

  faPlus = faPlus;

  stations: Station[];
  cols: StationDescriptor[];
  stateValues: string[];
  displayedColumns: string[];
  dataSource: MatTableDataSource<Station>;
  editedRowId: Set<string> = new Set<string>();

  @ViewChild(MatTable, {static: true}) stationTable: MatTable<any>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private stationsService: StationsService,
              public dialog: MatDialog,
              private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.stationsService.getStations()
        .subscribe(stations => {
          stations.forEach(station => {
            if (!station.fuels) {
              station.fuels = { cng: 'UNAVAILABLE', lng: 'UNAVAILABLE' };
            }
          });
          this.dataSource = new MatTableDataSource(stations);
          this.dataSource.paginator = this.paginator;
          this.dataSource.paginator.pageSize = this._PAGINATOR_DEFAULT_PAGE_SIZE;
          this.dataSource.sort = this.sort;
        });

    this.cols = [
      {field: 'latitude', header: 'Latitude', show: false, type: 'number', required: true},
      {field: 'longitude', header: 'Longitude', show: false, type: 'number', required: true},
      {field: 'name', header: 'Name', show: true, type: 'string', required: true},
      {field: 'country', header: 'Country', show: true, type: 'string', required: false},
      {field: 'city', header: 'City', show: true, type: 'string', required: false},
      {field: 'cp', header: 'Cp', show: true, type: 'string', required: false},
      {field: 'address', header: 'Address', show: false, type: 'string', required: false},
      {field: 'fuels', header: 'Fuels', show: true, type: 'fuel', required: true},
      {field: 'details', header: 'Details', show: true, type: 'textarea', required: false},
      {field: 'notices', header: 'Notices', show: true, type: 'textarea', required: false},
      {field: 'training', header: 'Training & Guide', show: true, type: 'training', required: false},
      {field: 'isOperational', header: 'Operational', show: true, type: 'bool', required: false}
    ];

    // pipe a cols-ra
    this.displayedColumns = ['name', 'country', 'city', 'cp', 'fuels', 'isOperational', 'details', 'notices', 'training', 'edit'];
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  showStationDialog(station: Station) {
    const isNew = station === undefined;
    const editedRowNumber = isNew ? -1 : this.dataSource.data.indexOf(station);

    const dialogRef = this.dialog.open(StationComponent, {
      width: '720px',
      data: {
        cols: this.cols,
        station: isNew ? new Station() : JSON.parse(JSON.stringify(station))
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (isNew) {
          this.stations = this.dataSource.data;
          this.stations.push(result);
          this.dataSource.data = this.stations;

          this.snackBar.openFromComponent(CustomSnackBarComponent, {
            panelClass: 'snack-bar-container-succesfully',
            data: {
              message: 'New station has been added to the grid!'
            }
          });
        } else {
          this.stations = this.dataSource.data;
          this.stations[editedRowNumber] = result;
          this.dataSource.data = this.stations;

          this.editedRowId.add(this.stations[editedRowNumber].ID);

          this.snackBar.openFromComponent(CustomSnackBarComponent, {
            panelClass: 'snack-bar-container-succesfully',
            data: {
              message: 'Modifications has been saved!'
            }
          });
        }
      }
    });
  }

  deleteRow(station: Station) {
    const index = this.dataSource.data.indexOf(station);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '700px',
      data: {
        title: 'Delete station!',
        message: 'Do you want to delete "'.concat(this.dataSource.data[index].country, '-',
          this.dataSource.data[index].name, '" from your local list?'),
        action: 'Delete',
        actionButtonType: 'warn'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.stations = this.dataSource.data;
        this.stations.splice(index, 1);
        this.dataSource.data = this.stations;

        this.snackBar.openFromComponent(CustomSnackBarComponent, {
          panelClass: 'snack-bar-container-succesfully',
          data: {
            message: 'Station has been deleted!'
          }
        });
      }
    });
  }

  refreshGrid() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '700px',
      data: {
        title: 'Refresh stations grid!',
        message: 'This action will be discard all of your local changes!',
        action: 'Refresh'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.stationsService.getStations()
        .subscribe(stations => {
          this.editedRowId.clear();

          this.dataSource.data = stations;
          this.snackBar.openFromComponent(CustomSnackBarComponent, {
            panelClass: 'snack-bar-container-succesfully',
            data: {
              message: 'Station grid has been updated!'
            }
          });
        });
      }
    });
  }

  uploadJson() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '700px',
      data: {
        title: 'Upload stations!',
        message: 'This action will be upload all of your local changes to the server!',
        action: 'Upload'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.stationsService.pushStations(this.dataSource.data.filter(station => this.editedRowId.has(station.ID))).subscribe( _ => {
          this.editedRowId.clear();

          this.snackBar.openFromComponent(CustomSnackBarComponent, {
            panelClass: 'snack-bar-container-succesfully',
            data: {
              message: 'The list has been uploaded succesfully!'
            }
          });

          this.stationsService.getStations()
            .subscribe(stations => this.dataSource.data = stations);
        });
      }
    });
  }

  showMapsModal(station: Station) {
    if (station !== undefined && station.latitude !== undefined && station.longitude !== undefined) {
      this.dialog.open(MapDialogComponent, {
        width: '75vw',
        height: '75vh',
        maxWidth: '80vw',
        maxHeight: '80vh',
        data: {
          name: station.name,
          latitude: station.latitude,
          longitude: station.longitude
        }
      });
    }
  }

}
