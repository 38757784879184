import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MaterialCollectorModule } from '../material-module';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { KeyFilterModule } from 'primeng/keyfilter';
import { GMapModule } from 'primeng/gmap';

import { StationsComponent } from './components/stations/stations.component';
import { UsersComponent } from './components/users/users.component';
import { UsableStationDataPipe } from './pipes/usable-station-data.pipe';
import { StationComponent } from './dialogs/station-dialog/station.component';
import { MapDialogComponent } from './dialogs/map-dialog/map-dialog.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { CustomSnackBarComponent } from './components/custom-snack-bar/custom-snack-bar.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { HelpComponent } from './components/help/help.component';
import { HelpDialogComponent } from './dialogs/help-dialog/help-dialog.component';
import { HeaderComponent } from './components/header/header.component';
import { SidenavListComponent } from './components/sidenav-list/sidenav-list.component';
import { SettingsComponent } from './components/settings/settings.component';
import { RoleDialogComponent } from './dialogs/role-dialog/role-dialog.component';
import { EmailConfirmComponent } from './components/email-confirm/email-confirm.component';
import { ProgressSpinnerDialogComponent } from './dialogs/progress-spinner-dialog/progress-spinner-dialog.component';
import { AdalService, AdalGuard, AdalInterceptor } from 'adal-angular4';
import { LogoutComponent } from './components/logout/logout.component';
import { LoginComponent } from './components/login/login.component';
import { JwtInterceptor } from './JwtInterceptor';
import { ErrorInterceptor } from './ErrorInterceptor';
import { GuidesComponent } from './components/guides/guides.component';
import { HeadlineComponent } from './components/common/headline/headline.component';
import { UpdateTrainingGuideDialogComponent } from './dialogs/update-training-guide-dialog/update-training-guide-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    StationsComponent,
    UsersComponent,
    UsableStationDataPipe,
    StationComponent,
    MapDialogComponent,
    ConfirmDialogComponent,
    CustomSnackBarComponent,
    HelpComponent,
    HelpDialogComponent,
    HeaderComponent,
    SidenavListComponent,
    SettingsComponent,
    RoleDialogComponent,
    EmailConfirmComponent,
    ProgressSpinnerDialogComponent,
    LogoutComponent,
    LoginComponent,
    GuidesComponent,
    HeadlineComponent,
    UpdateTrainingGuideDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialCollectorModule,
    TableModule,
    ToastModule,
    DialogModule,
    ButtonModule,
    KeyFilterModule,
    GMapModule,
    HttpClientModule
  ],
  providers: [
    // Adal8Service,
    // {
    //   provide: Adal8HTTPService,
    //   useFactory: Adal8HTTPService.factory,
    //   deps: [
    //     HttpClient,
    //     Adal8Service
    //   ]
    // },
    AdalService,
    // AdalGuard,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AdalInterceptor,
    //   multi: true
    // },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {
        duration: 2500,
        horizontalPosition: 'right',
        verticalPosition: 'top'
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    }
  ],
  entryComponents: [
    StationComponent,
    MapDialogComponent,
    ConfirmDialogComponent,
    CustomSnackBarComponent,
    HelpDialogComponent,
    RoleDialogComponent,
    ProgressSpinnerDialogComponent,
    UpdateTrainingGuideDialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
