import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NavbarService } from 'src/app/services/navbar.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginButtonText = 'Login with Azure';

  constructor(
    private authService: AuthService,
    private navbarService: NavbarService) {
      this.navbarService.hide();
  }

  get adalStatus() {
    return this.authService.isAdalLoginActive;
  }

  ngOnInit() {
    if (this.authService.isAdalLoginActive) {
      this.loginButtonText = 'Login to API';
    }
  }

  login() {
    this.authService.login();
  }

}
