import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'usableStationData'
})
export class UsableStationDataPipe implements PipeTransform {

  transform(stations: any[]): any[] {
    return stations.filter((station) => {
      return station.show;
    });
  }

}
