import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HelpDialogComponent } from 'src/app/dialogs/help-dialog/help-dialog.component';
import { Help, HELP_DATA } from 'src/app/models/help';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent {

  helpData: Help[];

  constructor(public dialog: MatDialog) {
    this.helpData = HELP_DATA;
  }

  showHelpDialog(helpDescriptor: Help) {
    this.dialog.open(HelpDialogComponent, {
      width: '70%',
      data: {
        helpDescriptor
      }
    });
  }
}
