import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Station } from '../../models/station';
import { StationDescriptor } from '../../models/station-descriptor';
import { StationsService } from 'src/app/services/stations.service';
import { FuelStatus } from 'src/app/models/fuel-status';
import { TrainingGuideService } from 'src/app/services/training-guide.service';
import { TrainingGuideModel } from 'src/app/models/training-guide-model';

@Component({
  selector: 'app-station',
  templateUrl: './station.component.html',
  styleUrls: ['./station.component.scss']
})
export class StationComponent {

  station: Station;
  cols: StationDescriptor;
  fuelStatuses: FuelStatus[];
  trainingsGuides: TrainingGuideModel[];
  defaultSelectedTrainingAndGuide: string;

  constructor(public dialogRef: MatDialogRef<StationComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private stationsService: StationsService,
              private documentService: TrainingGuideService) {
      this.stationsService.getFuelStatuses()
      .subscribe( fuelStatuses => this.fuelStatuses = fuelStatuses);
      this.documentService.getDocuments()
      .subscribe( res => {
        this.trainingsGuides =
          res && res.length >= 0 ? [{name: '-', id: 'empty'} as TrainingGuideModel].concat(res) : [{name: '-', id: 'empty'} as TrainingGuideModel];
        this.defaultSelectedTrainingAndGuide =
          this.station.trainingGuides && this.station.trainingGuides[0] ? this.station.trainingGuides[0].id : '';
      });

      this.station = data.station;
      this.cols = data.cols;
      if (!this.station.fuels) {
        this.station.fuels = { cng: 'UNAVAILABLE', lng: 'UNAVAILABLE' };
      }
  }

  selectTraining(event: any): void {
    if (event.value === 'empty') {
      this.station.trainingGuides = [];
    } else {
      const tmp = this.trainingsGuides.find(x => x.id === event.value);
      this.station.trainingGuides = [tmp];
    }
  }
}
