import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {
  visible: boolean;
  public visibilityChangeEvent = new EventEmitter<boolean>();

  constructor() {
    this.visible = true;
  }

  private changeVisibility(value: boolean) {
    this.visible = value;
    this.visibilityChangeEvent.emit(value);
  }

  hide() {
    this.changeVisibility(false);
  }

  show() {
    this.changeVisibility(true);
  }
}
