import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-map-dialog',
  templateUrl: './map-dialog.component.html',
  styleUrls: ['./map-dialog.component.scss']
})
export class MapDialogComponent {

  dialogTitle = 'Station location';
  options: any;
  overlays: any[];

  constructor(public dialogRef: MatDialogRef<MapDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
                this.dialogTitle = this.dialogTitle + ' - ' + data.name + ' (' + data.latitude + ', ' + data.longitude + ')';

                this.options = {
                  center: {lat: data.latitude, lng: data.longitude},
                  zoom: 12
                };

                this.overlays = [
                  // @ts-ignore
                  new google.maps.Marker({
                    position: {
                      lat: data.latitude, lng: data.longitude},
                      title: data.name
                    }
                  )
                ];
              }
}
