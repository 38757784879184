import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Station } from 'src/app/models/station';
import { environment } from 'src/environments/environment';
import { FuelStatus } from '../models/fuel-status';

@Injectable({
  providedIn: 'root'
})
export class StationsService {

  private apiUrl: string;
  private getStationsUrl = 'stationmarker/v1/get';
  private pushStationsUrl = 'stationmarker/v1/update';
  private getFuelStatus = 'stationmarker/v1/getfuelstatuses';

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

  getStations() {
    return this.http.get<Station[]>(this.apiUrl + this.getStationsUrl);
  }

  pushStations(stations: Station[]) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        // 'Authorization': 'my-auth-token'
        'Access-Control-Allow-Origin': '*'
      })
    };

    return this.http.post(this.apiUrl + this.pushStationsUrl, JSON.stringify(stations), httpOptions);
  }

  getFuelStatuses() {
    return this.http.get<FuelStatus[]>(this.apiUrl + this.getFuelStatus);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: snack-bar

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
