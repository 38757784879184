import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { StationsComponent } from './components/stations/stations.component';
import { UsersComponent } from './components/users/users.component';
import { HelpComponent } from './components/help/help.component';
import { SettingsComponent } from './components/settings/settings.component';
import { EmailConfirmComponent } from './components/email-confirm/email-confirm.component';
import { AuthGuard } from './auth-guard';
import { LogoutComponent } from './components/logout/logout.component';
import { LoginComponent } from './components/login/login.component';
import { GuidesComponent } from './components/guides/guides.component';

const routes: Routes = [
  { path: '', component: StationsComponent, canActivate: [AuthGuard] },
  { path: 'stations', component: StationsComponent, canActivate: [AuthGuard] },
  { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
  { path: 'help', component: HelpComponent, canActivate: [AuthGuard] },
  { path: 'activate/:token', component: EmailConfirmComponent },
  { path: 'login', component: LoginComponent},
  { path: 'logout', component: LogoutComponent, canActivate: [AuthGuard] },
  { path: 'guides', component: GuidesComponent, canActivate: [AuthGuard] },

  // otherwise redirect to stations
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
