import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Role } from 'src/app/models/role';
import { SettingsService } from 'src/app/services/settings.service';
import { RoleDialogComponent } from 'src/app/dialogs/role-dialog/role-dialog.component';
import { CustomSnackBarComponent } from '../custom-snack-bar/custom-snack-bar.component';
import { ConfirmDialogComponent } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  roles: Role[];
  editedRoleId: Set<string> = new Set<string>();

  constructor(private settingsService: SettingsService,
              public dialog: MatDialog,
              private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.settingsService.getRoles()
        .subscribe(roles => {
          this.roles = roles;
        });
  }

  showRoleDialog(role: Role) {
    const isNew = role === undefined;
    if (isNew) {
      role = new Role();
    }
    const editedRowNumber = isNew ? -1 : this.roles.indexOf(role);
    const dialogRef = this.dialog.open(RoleDialogComponent, {
      width: '1000px',
      height: '900px',
      data: {
        role: JSON.parse(JSON.stringify(role))
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (isNew) {
          this.roles.push(result);

          this.snackBar.openFromComponent(CustomSnackBarComponent, {
            panelClass: 'snack-bar-container-succesfully',
            data: {
              message: 'New role has been added to the list!'
            }
          });
        } else {
          /*this.settingsService.updateRole(result).subscribe( _ =>
            this.settingsService.getRoles().subscribe(roles => this.roles = roles)
            );*/
          this.roles[editedRowNumber] = result;
          this.editedRoleId.add(this.roles[editedRowNumber].userlevel);

          this.snackBar.openFromComponent(CustomSnackBarComponent, {
            panelClass: 'snack-bar-container-succesfully',
            data: {
              message: 'Modifications has been saved!'
            }
          });
        }
      }
    });
  }

  refreshCardList() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '700px',
      data: {
        title: 'Refresh card list!',
        message: 'This action will be discard all of your local changes!',
        action: 'Refresh'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.settingsService.getRoles()
        .subscribe(roles => {
          this.editedRoleId.clear();

          this.roles = roles;
          this.snackBar.openFromComponent(CustomSnackBarComponent, {
            panelClass: 'snack-bar-container-succesfully',
            data: {
              message: 'Role cards has been updated!'
            }
          });
        });
      }
    });
  }

  uploadRoles() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '700px',
      data: {
        title: 'Upload roles!',
        message: 'This action will be upload all of your local changes to the server!',
        action: 'Upload'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.settingsService.pushRoles(this.roles).subscribe( _ => {
          this.editedRoleId.clear();

          this.snackBar.openFromComponent(CustomSnackBarComponent, {
            panelClass: 'snack-bar-container-succesfully',
            data: {
              message: 'The list has been uploaded succesfully!'
            }
          });
        });
      }
    });
  }
}
