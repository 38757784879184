/**
 * Practically this class represents the "Level object", but named Role instead of Level.
 */
export class Role {
    levelName: string;
    gasTransApp: boolean;
    transApp: boolean;
    transactionExFields: string[];
    gasTransactionFields: string[];
    userlevel: string;
    validStationMarkerFields: string[];
}
