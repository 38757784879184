import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TrainingGuideDto } from 'src/app/models/training-guide-dto';
import { TrainingGuideModel } from 'src/app/models/training-guide-model';
import { LanguageModel } from 'src/app/models/language-model';
import { User } from 'src/app/models/user-models';
import { TrainingGuideService } from 'src/app/services/training-guide.service';

@Component({
  selector: 'app-update-training-guide-dialog',
  templateUrl: './update-training-guide-dialog.component.html',
  styleUrls: ['./update-training-guide-dialog.component.scss']
})
export class UpdateTrainingGuideDialogComponent implements OnInit {
  document: TrainingGuideModel;
  user: User;

  uploadDocumentForm: FormGroup;
  chosenTrainingFile: File;
  chosenGuideFile: File;

  documentLanguages: LanguageModel[];

  constructor(public dialogRef: MatDialogRef<UpdateTrainingGuideDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {doc: TrainingGuideModel},
              private documentService: TrainingGuideService) {
    this.document = this.data.doc;
    this.getData();
  }

  ngOnInit() {
    this.uploadDocumentForm = new FormGroup({
      name: new FormControl({value: this.document.name, disabled: true}, [Validators.required]),
      language: new FormControl({value: this.document.language, disabled: true}, [Validators.required]),
      trainingName: new FormControl(this.document.trainingFileName, [this.fileChosenValidator()]),
      guideName: new FormControl(this.document.guideFileName, [this.fileChosenValidator()])
    });
  }

  fileChosenValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (this.uploadDocumentForm
          && this.uploadDocumentForm.get('trainingName').value === ''
          && this.uploadDocumentForm.get('guideName').value === '') {
            return { fileChosen: true };
        }
        return null;
    };
  }

  formHasError(): boolean {
    if (this.uploadDocumentForm.hasError('fileChosen', 'trainingName')
     && this.uploadDocumentForm.hasError('fileChosen', 'guideName')) {
       return true;
    } else {
      return false;
    }
  }

  private getData() {
    this.documentService.getDocLanguages()
      .subscribe(data => {
        this.documentLanguages = data;
      });
  }

  /**
   * Get the chosen file from the specific input element.
   * @param event object of binded change event
   * @param type 0 -> training, 1 -> guide, 2 -> refresh
   */
  onFileSelected(event: any, type: number) {
    let inputNode: any;
    if (type === 2) {
      inputNode = document.querySelector('#fileRefresh_dialog');
    } else if (type === 0) {
      inputNode = document.querySelector('#fileTraining_dialog');
      this.chosenTrainingFile = inputNode.files[0];
      this.uploadDocumentForm.get('trainingName').setValue(this.chosenTrainingFile.name);
    } else {
      inputNode = document.querySelector('#fileGuide_dialog');
      this.chosenGuideFile = inputNode.files[0];
      this.uploadDocumentForm.get('guideName').setValue(this.chosenGuideFile.name);
    }
    this.uploadDocumentForm.updateValueAndValidity();
  }

  close() {
    const result = {
      Name: this.document.name,
      Language: this.document.language,
      TrainingFileToUpload: this.chosenTrainingFile,
      GuideFileToUpload: this.chosenGuideFile
    } as TrainingGuideDto;
    this.dialogRef.close(result);
  }

  deleteTrainingFile(): void {
    this.chosenTrainingFile = undefined;
    this.uploadDocumentForm.get('trainingName').setValue(this.document.trainingFileName);
    this.uploadDocumentForm.updateValueAndValidity();
  }

  deleteGuideFile(): void {
    this.chosenGuideFile = undefined;
    this.uploadDocumentForm.get('guideName').setValue(this.document.guideFileName);
    this.uploadDocumentForm.updateValueAndValidity();
  }

}
