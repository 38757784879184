import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Role } from 'src/app/models/role';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { SettingsService } from 'src/app/services/settings.service';

enum LVL_Constants {
  LVL_ValidStationMarker = "ValidStationMarker",
  LVL_User = "User",
  LVL_TransactionExt = "TransactionExt",
  LVL_GasTransaction = "GasTransaction"
}

@Component({
  selector: 'app-role-dialog',
  templateUrl: './role-dialog.component.html',
  styleUrls: ['./role-dialog.component.scss']
})
export class RoleDialogComponent implements OnInit {
  role: Role;
  optionsTransactionExt: string[];
  optionsValidStationMarkers: string[];
  optionsGasTransaction: string[];

  constructor(private settingsService: SettingsService,
              public dialogRef: MatDialogRef<RoleDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
      this.role = data.role;
  }

  ngOnInit() {
    this.settingsService.getColumnsForGrid(LVL_Constants.LVL_TransactionExt)
      .subscribe(opt => {
        this.optionsTransactionExt = opt.filter((item) => {
          return !this.role.transactionExFields.includes(item);
        });
      });
    this.settingsService.getColumnsForGrid(LVL_Constants.LVL_ValidStationMarker)
      .subscribe(opt => {
        this.optionsValidStationMarkers = opt.filter((item) => {
          return !this.role.validStationMarkerFields.includes(item);
        });
      });
    this.settingsService.getColumnsForGrid(LVL_Constants.LVL_GasTransaction)
      .subscribe(opt => {
        this.optionsGasTransaction = opt.filter((item) => {
          return !this.role.gasTransactionFields.includes(item);
        });
      });
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
}
