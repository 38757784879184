import { Component, OnInit } from '@angular/core';
import { NavbarService } from './services/navbar.service';
import { LoggedInUser } from './models/LoggedInUser';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  visible: boolean;
  user: LoggedInUser;

  constructor(private navbarService: NavbarService,
    private authService: AuthService) {
    this.visible = this.navbarService.visible;
  }

  ngOnInit() {
    this.navbarService.visibilityChangeEvent
      .subscribe((visible: boolean) => this.visible = visible);

    this.authService.userLoginEvent.subscribe((user: LoggedInUser) => {
      this.user = user;
    });
  }
}
