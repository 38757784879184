import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { User } from '../models/user-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private apiUrl: string;
  private getUsersUrl = 'user/v1/get';
  private pushUsersUrl = 'user/v1/TODO';
  private approveUserUrl = 'reg/v1/approvelist';
  private approveUsersUrl = 'reg/v1/approvelist';
  private levelComboUrl = 'user/v1/levelcombo';
  private customerComboUrl = 'user/v1/customercombo';
  private userModUrl = 'user/v1/mod';

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

  getUsers() {
    return this.http.get<User[]>(this.apiUrl + this.getUsersUrl);
  }

  pushUsers(users: User[]) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };

    return this.http.post(this.apiUrl + this.pushUsersUrl, JSON.stringify(users), httpOptions);
  }

  approveUserRequest(user: User) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };

    return this.http.post(this.apiUrl + this.approveUserUrl + '/' + user.id, httpOptions);
  }

  approveAllUserRequest(userIdList: string[]) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };

    return this.http.post(this.apiUrl + this.approveUsersUrl, userIdList, httpOptions);
  }

  getLevelComboOptions(): Observable<{ [name: string]: string }> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };

    return this.http.get<{ [name: string]: string }>(this.apiUrl + this.levelComboUrl, httpOptions);
  }

  getCustomerComboOptions(): Observable<[string, string][]> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };

    return this.http.get<{ [name: string]: string }>(this.apiUrl + this.customerComboUrl, httpOptions)
    .pipe(
      map(data => {
        // Converts it to ordered by value [string, string][] array, where first string is the key, second one is the value.
        let entries = Object.entries(data);
        let sorted = entries.sort((a, b) => a[1].localeCompare(b[1], 'en'));
        return sorted;
      })
    );
  }

  updateUser(user: User) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      })
    };

    return this.http.put(this.apiUrl + this.userModUrl, user, httpOptions);
  }

}
