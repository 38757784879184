import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, pipe } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LanguageModel } from '../models/language-model';
import { TypeModel } from '../models/type-model';
import { TrainingGuideModel } from '../models/training-guide-model';
import { TrainingGuideDto } from '../models/training-guide-dto';
import { Config } from 'protractor';
import { catchError, map, tap } from 'rxjs/operators';
import { RefreshGuideTrainingDto } from '../models/refresh-guide-training-dto';

@Injectable({
  providedIn: 'root'
})
export class TrainingGuideService {
  private readonly TRAINING_GUIDE_CONTROLLER = 'trainingguide/v1/';
  private readonly DOCUMENT_CONTROLLER = 'document/v1/';

  constructor(private http: HttpClient) { }

  getDocLanguages(): Observable<LanguageModel[]> {
    return this.http.get<LanguageModel[]>(environment.apiUrl + this.TRAINING_GUIDE_CONTROLLER + 'languages');
  }

  getDoctypes(): Observable<TypeModel[]> {
    return this.http.get<TypeModel[]>(environment.apiUrl + this.DOCUMENT_CONTROLLER + 'getdoctypesguidetraining');
  }

  addDocument(dto: TrainingGuideDto): Observable<any> {
    const fd = new FormData();
    fd.append('Name', dto.Name);
    fd.append('Language', dto.Language);
    fd.append('TrainingFileToUpload', dto.TrainingFileToUpload);
    fd.append('GuideFileToUpload', dto.GuideFileToUpload);
    return this.http.post(environment.apiUrl + this.TRAINING_GUIDE_CONTROLLER + 'addtrainingguide', fd);
  }

  getDocuments(): Observable<TrainingGuideModel[]> {
    return this.http.get<TrainingGuideModel[]>(environment.apiUrl + this.TRAINING_GUIDE_CONTROLLER + 'get');
  }

  getDocument(id: number): Observable<TrainingGuideModel> {
    return this.http.get<TrainingGuideModel>(environment.apiUrl + this.TRAINING_GUIDE_CONTROLLER + 'get' + '/' + id);
  }

  deleteDocument(id: number | string): Observable<any> {
    return this.http.delete<any>(environment.apiUrl + this.TRAINING_GUIDE_CONTROLLER + 'delete' + '/' + id);
  }

  refreshFile(dto: TrainingGuideDto): Observable<any> {
    const fd = new FormData();
    fd.append('Name', dto.Name);
    fd.append('Language', dto.Language);
    fd.append('TrainingFileToUpload', dto.TrainingFileToUpload);
    fd.append('GuideFileToUpload', dto.GuideFileToUpload);
    return this.http.put(environment.apiUrl + this.TRAINING_GUIDE_CONTROLLER + 'modtrainingguide', fd);
  }

  downloadTrainingGuidePair(id: string): Observable<any> {
    return this.http.get(environment.apiUrl + this.TRAINING_GUIDE_CONTROLLER + 'download/' + id, {responseType: 'blob' as 'json'});
  }

  downloadTrainingOrGuideFile(fileName: string): Observable<any> {
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Content-Type', 'application/pdf');
    return this.http.get(environment.apiUrl + this.TRAINING_GUIDE_CONTROLLER + 'downloadtgfile/' + fileName,
      {
        headers: requestHeaders, responseType: 'blob'
      }
    );
  }
}
